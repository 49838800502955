export const server = "https://api.visitandbuy.shop/api/v2";
// export const server = "http://localhost:8000/api/v2";

export const backend_url = "https://api.visitandbuy.shop/";
// export const backend_url = "http://localhost:8000/";

export const frontend_url = "https://visitandbuy.shop";
// export const frontend_url = "http://localhost:3000";


